<template>
  <v-dialog
    :value="dlg.visible"
    width="520"
    persistent
  >
    <v-card
      class="white"
      flat
    >
      <v-card-title>
        <span>{{ dlg.title }}</span>
        <v-spacer />
        <v-btn
          icon
          @click="onClose"
        >
          <v-icon>
            {{ btnClose.icon }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text
        class="px-5 pt-8"
      >
        <v-text-field
          v-model="wdTitle.value"
          :label="wdTitle.label"
          :maxlength="wdTitle.max"
          outlined
        />
        <v-textarea
          v-model="wdRemark.value"
          :label="wdRemark.label"
          :maxlength="wdRemark.max"
          outlined
        />
        <v-text-field
          v-model="wdKeyword.value"
          :label="wdKeyword.label"
          :maxlength="wdKeyword.max"
          outlined
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue-grey darken-3"
          text
          @click="onClose"
        >
          关闭
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="onConfirm"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import app from '@/api/co.app'
  import web from '@/api/web/co.web'

  export default {
    props: {
    },
    data () {
      return {
        info: {},
        btnClose: {
          icon: 'mdi-close'
        },
        wdTitle: {
          label: '标题',
          value: '',
          max: 64,
          required: true
        },
        wdRemark: {
          label: '描述',
          value: '',
          max: 128
        },
        wdKeyword: {
          label: '关键字',
          value: '',
          max: 64
        },
        dlg: {
          title: '影集信息',
          visible: false,
        },
      }
    },
    methods: {
      show (item) {
        this.info = item
        this.wdTitle.value = item.title
        this.wdRemark.value = item.remark || ''
        this.wdKeyword.value = item.keyword || ''
        this.dlg.visible = true
      },
      hide () {
        this.dlg.visible = false
      },
      getData () {
        const result = {
          title: this.wdTitle.value,
          remark: this.wdRemark.value,
          keyword: this.wdKeyword.value
        }

        return result
      },
      onConfirm () {
        const me = this
        const data = this.getData()
        const executed = function (res) {
          if (res.status) {
            app.emit(app.event.ALBUM_INFO_CHANGE, data)
            me.hide()
            me.$notify({
              title: '成功提示',
              message: '影集信息更新成功！',
              type: 'success'
            })
          } else {
            me.$notify({
              title: '影集信息更新失败',
              message: `${res.data.code} - ${res.data.desc}`,
              type: 'error'
            })
          }
        }
        web.album.update({
          id: this.info.albumId,
          data,
          executed
        })
      },
      onClose () {
        this.hide()
      },
    }
  }
</script>
